import React from 'react';

export default {
  sm: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0H16V16H0z" />
        <path
          className="icon-color"
          d="M12.984 11.608l1.302-1.322c.384-.384 1.024-.384 1.408 0 .384.384.384 1.024 0 1.408l-2.966 3.008c-.191.191-.448.298-.704.298-.256 0-.511-.107-.704-.298l-3.03-3.008c-.426-.427-.383-1.152.13-1.515.404-.298.98-.213 1.322.15l1.13 1.108.129.022V1.982c0-.555.447-.982.98-.982.577 0 1.004.448 1.004.982v9.626zM4.984 4.392l1.302 1.322c.384.384 1.024.384 1.408 0 .384-.384.384-1.024 0-1.408L4.728 1.298C4.537 1.107 4.28 1 4.024 1c-.256 0-.511.107-.704.298L.29 4.306c-.426.427-.383 1.152.13 1.515.404.298.98.213 1.322-.15l1.13-1.108.129-.022V14.018c0 .555.447.982.98.982.577 0 1.004-.448 1.004-.982V4.392z"
        />
      </g>
    </svg>
  ),
  md: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0H24V24H0z" />
        <path
          className="icon-color"
          d="M21.7 13.297c-.388-.39-1.024-.39-1.413 0l-2.29 2.29V6c0-.55-.45-1-1-1-.551 0-1 .45-1 1v9.587l-2.291-2.29c-.39-.389-1.025-.389-1.414 0-.39.389-.39 1.025 0 1.414l3.998 3.997c.058.06.132.092.2.133.042.025.076.061.122.081.04.018.087.018.13.028.212.057.436.056.64-.028.044-.019.077-.054.118-.079.07-.041.143-.074.204-.135l3.997-3.997c.389-.389.389-1.025 0-1.414M11.7 10.692c-.388.39-1.024.39-1.413 0l-2.29-2.29v9.587c0 .55-.45 1-1 1-.551 0-1-.45-1-1V8.402l-2.291 2.29c-.39.39-1.025.39-1.414 0-.39-.389-.39-1.025 0-1.414L6.29 5.281c.058-.06.132-.092.2-.133.042-.025.076-.06.122-.08.04-.019.087-.019.13-.029.212-.057.436-.056.64.028.044.02.077.054.118.08.07.04.143.073.204.134L11.7 9.278c.389.39.389 1.025 0 1.414"
        />
      </g>
    </svg>
  ),
};
