import React from 'react';

export default {
  sm: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0H16V16H0z" />
        <path
          className="icon-color"
          d="M5.988 7.77c.008.07.011.141.011.214 0 .078-.004.154-.013.23l4.962 3.094c.302-.185.658-.292 1.038-.292 1.097 0 1.988.89 1.988 1.987 0 1.098-.89 1.987-1.988 1.987-1.098 0-1.988-.889-1.988-1.987 0-.092.007-.183.019-.272L5.092 9.66c-.314.205-.69.324-1.092.324-1.104 0-2-.896-2-2s.896-2 2-2c.408 0 .788.122 1.105.333l4.897-3.065c-.01-.083-.016-.167-.016-.252 0-1.105.896-2 2-2s2 .895 2 2c0 1.104-.896 2-2 2-.395 0-.763-.115-1.074-.313L5.988 7.77z"
        />
      </g>
    </svg>
  ),
  md: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0H24V24H0z" />
        <path
          className="icon-color"
          fillRule="nonzero"
          d="M9.835 10.938c.107.336.165.694.165 1.066 0 .37-.058.729-.165 1.064l5.086 3.082c.64-.702 1.561-1.142 2.586-1.142 1.933 0 3.5 1.567 3.5 3.5 0 1.934-1.567 3.5-3.5 3.5-1.934 0-3.5-1.566-3.5-3.5 0-.186.014-.37.042-.548l-5.345-3.238c-.601.489-1.369.782-2.204.782-1.933 0-3.5-1.568-3.5-3.5 0-1.934 1.567-3.5 3.5-3.5.835 0 1.602.292 2.204.78l5.345-3.238c-.028-.178-.042-.36-.042-.546 0-1.933 1.567-3.5 3.5-3.5s3.5 1.567 3.5 3.5S19.44 9 17.507 9c-1.025 0-1.947-.44-2.587-1.143l-5.085 3.08zm9.172-5.439c0-.828-.672-1.5-1.5-1.5-.829 0-1.5.672-1.5 1.5 0 .83.671 1.5 1.5 1.5.828 0 1.5-.67 1.5-1.5zM8 12.005c0-.83-.671-1.5-1.5-1.5s-1.5.67-1.5 1.5c0 .828.671 1.5 1.5 1.5.828 0 1.5-.672 1.5-1.5zm11.007 6.504c0-.828-.672-1.5-1.5-1.5-.829 0-1.5.672-1.5 1.5 0 .829.671 1.5 1.5 1.5.828 0 1.5-.671 1.5-1.5z"
        />
      </g>
    </svg>
  ),
};
