import React from 'react';

export default {
  sm: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0H16V16H0z" />
        <path
          className="icon-color"
          d="M7.511 1.131c.27-.153.592-.172.875-.057l.118.058 6.994 4.004c.363.208.53.586.5.95l.002.061v6.816c0 .552-.447 1-1 1-.512 0-.935-.386-.993-.883L14 12.963V7.726l-.999.57.001 2.95c0 2.172-2.3 3.76-5 3.76-2.62 0-4.864-1.495-4.994-3.57l-.006-.19v-2.95L.504 6.872c-.633-.36-.67-1.24-.112-1.663l.111-.074 7.007-4.004zm-2.51 8.305l.001 1.81c0 .876 1.281 1.76 3 1.76 1.65 0 2.897-.815 2.994-1.655l.006-.106V9.438L8.5 10.866c-.269.154-.59.173-.873.058l-.118-.058-2.508-1.43zM8.006 3.15l-4.99 2.851 4.987 2.844 4.983-2.844-4.98-2.851z"
        />
      </g>
    </svg>
  ),
  md: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0H24V24H0z" />
        <path
          className="icon-color"
          d="M11.542 3.116c.256-.135.555-.151.822-.05l.112.05 10 5.287c.35.184.528.524.533.867l-.005.104.007.117v9.5c0 .552-.448 1-1 1-.513 0-.935-.386-.993-.884l-.007-.116v-7.982l-2.007 1.146.001 3.841c0 2.867-3.2 5-7 5-3.714 0-6.854-2.037-6.995-4.805l-.005-.195v-3.848l-3.5-2.001C.856 9.777.835 8.87 1.423 8.46l.11-.067 10.008-5.278zM7.004 13.291l.001 2.705c0 1.551 2.173 3 5 3 2.74 0 4.868-1.362 4.994-2.859l.006-.14v-2.7l-4.499 2.571c-.269.154-.591.173-.874.058l-.118-.058-4.51-2.577zm5.004-8.16L4.077 9.313l7.933 4.535 7.924-4.528-7.926-4.189z"
        />
      </g>
    </svg>
  ),
};
