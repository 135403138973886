import React from 'react';

export default {
  sm: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0H16V16H0z" />
        <path
          className="icon-color"
          d="M12 14v-1h.001L12 14zm-.001-1H4V8h8l-.001 5zM8.001 3c1.654 0 3 1.329 3 2.964V6h-6v-.036c0-1.635 1.346-2.964 3-2.964zm5 3.279v-.315c0-2.737-2.243-4.964-5-4.964s-5 2.227-5 4.964v.313C2.406 6.625 2 7.263 2 8v5c0 1.103.897 2 2 2h8c1.103 0 2-.897 2-2V8c0-.736-.404-1.374-.999-1.721z"
        />
        <path
          className="icon-color"
          d="M9.002 9.81c0-.554-.449-1.002-1.002-1.002-.554 0-1.002.448-1.002 1.002 0 .295.132.554.335.736v.93c0 .372.3.672.67.672.37 0 .671-.3.671-.671v-.935c.198-.183.328-.44.328-.732"
        />
      </g>
    </svg>
  ),
  md: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0H24V24H0z" />
        <path
          className="icon-color"
          d="M18 18.997c0 .552-.448 1-1 1H7c-.552 0-1-.448-1-1v-7c0-.552.448-1 1-1h10c.552 0 1 .448 1 1v7zM12.004 4c2.206 0 4 1.795 4 4v.997h-8V8c0-2.206 1.794-4.001 4-4.001zm6 5.183V8c0-3.31-2.692-6.001-6-6.001-3.309 0-6 2.692-6 6v1.18C4.84 9.593 4 10.693 4 11.997v7c0 1.654 1.346 3 3 3h10c1.654 0 3-1.346 3-3v-7c0-1.301-.837-2.4-1.996-2.814z"
        />
        <path
          className="icon-color"
          d="M13.5 14.494c0-.83-.672-1.5-1.5-1.5s-1.5.67-1.5 1.5c0 .443.195.837.502 1.11v1.386c0 .554.449 1.003 1.003 1.003.555 0 1.004-.45 1.004-1.003v-1.392c.3-.275.491-.666.491-1.104"
        />
      </g>
    </svg>
  ),
};
