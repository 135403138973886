import React from 'react';

export default {
  sm: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0H16V16H0z" />
        <path
          className="icon-color"
          d="M2 13h10v-2H2v2zm0-5.997h3.213c.21.74.567 1.418 1.043 1.996H2V7.003zm0-4h3.604c-.312.61-.515 1.282-.579 1.997H2V3.003zM14 5.5C14 7.43 12.43 9 10.5 9S7 7.43 7 5.5 8.57 2 10.5 2 14 3.57 14 5.5zm2 0C16 2.462 13.538 0 10.5 0 9.323 0 8.235.373 7.34 1.003H2c-1.105 0-2 .894-2 2v2c0 .365.106.703.277.998-.171.295-.277.633-.277 1v2c0 .365.106.703.277.998-.171.296-.277.634-.277 1v2c0 1.104.895 2 2 2h10c1.104 0 2-.896 2-2v-2c0-.366-.105-.704-.277-1 .013-.022.019-.049.031-.072C15.114 8.927 16 7.318 16 5.5z"
        />
        <path
          className="icon-color"
          d="M10.5 4.482l1.018-1.02c.281-.28.737-.28 1.018 0 .283.283.283.739 0 1.02L11.52 5.5l1.017 1.018c.281.28.281.737 0 1.019-.282.28-.738.28-1.019 0L10.5 6.518l-1.018 1.02c-.281.28-.738.28-1.018 0-.281-.282-.281-.738 0-1.02L9.48 5.502 8.462 4.48c-.281-.28-.281-.736 0-1.018.28-.281.737-.281 1.019 0L10.5 4.482z"
        />
      </g>
    </svg>
  ),
  md: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0H24V24H0z" />
        <path
          className="icon-color"
          d="M17.99 18.996c0 .551-.448 1-1 1H4c-.552 0-1-.449-1-1v-2c0-.546.44-.988.983-.997l.017.002h12.99l.017-.002c.543.01.983.451.983.997v2zM3 11.001c0-.552.448-1 1-1h4.151c.318 1.565 1.13 2.948 2.258 3.995H4l-.017.001c-.543-.009-.983-.45-.983-.996v-2zm0-5.995c0-.552.448-1 1-1h5.506C8.66 5.134 8.124 6.506 8.026 8H4c-.552 0-1-.449-1-1V5.006zM21 8.5c0 3.032-2.467 5.5-5.5 5.5-3.032 0-5.5-2.468-5.5-5.5C10 5.467 12.468 3 15.5 3 18.533 3 21 5.467 21 8.5zm2 0C23 4.357 19.642 1 15.5 1c-1.364 0-2.639.37-3.74 1.006H4c-1.657 0-3 1.343-3 3V7c0 .772.3 1.47.78 2.001-.48.531-.78 1.228-.78 2.001v2c0 .77.3 1.466.776 1.997C1.3 15.53 1 16.225 1 16.996v2c0 1.656 1.343 3 3 3h12.99c1.657 0 3-1.344 3-3v-2c0-.768-.296-1.459-.77-1.989C21.475 13.714 23 11.287 23 8.5z"
        />
        <path
          className="icon-color"
          d="M15.5 7.086l1.414-1.414c.391-.391 1.024-.391 1.414 0 .391.391.391 1.023 0 1.414L16.914 8.5l1.414 1.414c.391.39.391 1.024 0 1.414-.39.391-1.023.391-1.414 0L15.5 9.914l-1.413 1.414c-.391.391-1.025.391-1.415 0-.391-.39-.391-1.024 0-1.414L14.086 8.5l-1.414-1.414c-.391-.391-.391-1.023 0-1.414.39-.391 1.024-.391 1.415 0L15.5 7.086z"
        />
      </g>
    </svg>
  ),
};
