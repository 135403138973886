import React from 'react';

export default {
  sm: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0H16V16H0z" />
        <path
          className="icon-color"
          d="M9.413 8l5.293 5.292c.39.39.39 1.025 0 1.414-.39.39-1.025.39-1.414 0L8 9.413l-5.293 5.293c-.39.39-1.025.39-1.414 0-.39-.389-.39-1.025 0-1.414L6.585 8 1.292 2.706c-.39-.389-.39-1.025 0-1.414.39-.39 1.024-.39 1.414 0L8 6.585l5.293-5.293c.39-.39 1.024-.39 1.414 0 .39.39.39 1.025 0 1.414L9.413 8z"
        />
      </g>
    </svg>
  ),
  md: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0H24V24H0z" />
        <path
          className="icon-color"
          d="M13.413 11.998l8.291 8.291c.39.39.39 1.025 0 1.414-.39.39-1.025.39-1.415 0l-8.291-8.29-8.292 8.29c-.39.39-1.025.39-1.415 0-.389-.389-.389-1.024 0-1.414l8.292-8.291-8.292-8.292c-.389-.39-.389-1.025 0-1.415.39-.389 1.025-.389 1.415 0l8.292 8.292 8.291-8.292c.39-.389 1.025-.389 1.415 0 .39.39.39 1.025 0 1.415l-8.291 8.292z"
        />
      </g>
    </svg>
  ),
};
