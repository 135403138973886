import React from 'react';

export default {
  sm: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0H16V16H0z" />
        <path
          className="icon-color"
          fillRule="nonzero"
          d="M12.981 1.007c.692-.032 1.162.014 1.52.276.079.059.15.129.209.208.668.895.147 4.028-1.08 5.533l-.133.155-.038.038.015.063c.415 2.099.085 4.057-.714 5.785-.337.728-.68 1.259-.928 1.566-.347.429-.968.491-1.391.161l-.094-.083-2.19-2.19-.245.246c-.892.89-2.292.312-3.53-.866l-.141-.138c-1.223-1.224-1.88-2.636-1.1-3.566l.097-.105.244-.246-2.189-2.19c-.42-.42-.383-1.112.079-1.485.307-.248.838-.591 1.565-.928 1.729-.8 3.687-1.128 5.786-.713l.062.016.063-.061c.926-.856 2.407-1.338 3.846-1.457l.287-.019zm-1.329 8.018l-2.082 2.08 1.292 1.29.083-.17c.456-.986.718-2.06.707-3.2zm1.337-6.014l-.15.01c-.95.083-1.933.384-2.486.805l-.13.108L4.877 9.28l.013.033c.096.213.263.467.48.725l.137.155.148.155c.307.306.629.551.903.699l.132.065.032.014 5.332-5.33c.391-.425.696-1.186.85-2.066.026-.147.046-.292.062-.432l.019-.205.004-.081zM4.053 4.935l-.276.122-.172.082L4.897 6.43 6.98 4.35c-1.034-.011-2.016.204-2.926.585z"
        />
      </g>
    </svg>
  ),
  md: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0H24V24H0z" />
        <path
          className="icon-color"
          fillRule="nonzero"
          d="M19.404 2c.927 0 1.596.097 1.996.398.073.054.137.118.192.19.91 1.188.344 5.855-1.35 7.941l-.157.183-.451.451.046.255c.485 2.884.001 5.578-1.106 7.97-.486 1.05-.979 1.812-1.326 2.243-.347.429-.968.49-1.391.161l-.094-.083-3.337-3.338-.72.722c-1.084 1.082-3.042.183-4.868-1.58l-.177-.172c-1.815-1.816-2.805-3.8-1.85-4.938l.1-.108.718-.719L2.293 8.24c-.42-.42-.383-1.112.079-1.485.43-.348 1.191-.84 2.242-1.327 2.462-1.14 5.244-1.619 8.225-1.06l.454-.453.22-.19C14.936 2.562 17.23 2 19.403 2zm-1.558 10.952l-4.006 4.005 2.467 2.468c.106-.183.207-.372.305-.571l.147-.306c.792-1.71 1.21-3.593 1.087-5.596zM19.404 4c-1.735 0-3.587.453-4.57 1.226l-.155.131-8.212 8.21.025.093.051.147.034.082.061.133c.274.565.787 1.253 1.438 1.904.651.652 1.339 1.165 1.904 1.438.092.045.178.082.254.11l.108.037.093.024 8.196-8.196c.646-.706 1.096-1.96 1.283-3.416.064-.502.092-.998.086-1.43l-.008-.25-.016-.22-.228-.015-.167-.006L19.404 4zM5.454 7.243c-.209.097-.408.196-.597.296l-.281.155 2.468 2.467 4.007-4.004c-2.004-.124-3.887.294-5.597 1.086z"
        />
      </g>
    </svg>
  ),
};
