import React from 'react';

export default {
  sm: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0H16V16H0z" />
        <path
          className="icon-color"
          d="M10.006 3c-2.762 0-5 2.238-5 5s2.238 5 5 5c2.76 0 5-2.238 5-5s-2.24-5-5-5m0 2c1.654 0 3 1.346 3 3s-1.346 3-3 3-3-1.346-3-3 1.346-3 3-3"
        />
        <path
          className="icon-color"
          fillRule="nonzero"
          d="M3.427 3.65C1.936 4.535 1 6.18 1 8c0 1.821.937 3.467 2.428 4.35.475.281 1.088.124 1.37-.35.281-.476.124-1.09-.351-1.37C3.565 10.106 3 9.114 3 8s.564-2.106 1.448-2.63c.475-.283.631-.896.35-1.371-.283-.475-.896-.631-1.371-.35z"
        />
      </g>
    </svg>
  ),
  md: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0H24V24H0z" />
        <path
          className="icon-color"
          d="M14.998 5c-3.865 0-7 3.134-7 7s3.135 7 7 7c3.866 0 7-3.134 7-7s-3.134-7-7-7m0 2c2.758 0 5 2.242 5 5s-2.242 5-5 5c-2.757 0-5-2.242-5-5s2.243-5 5-5"
        />
        <path
          className="icon-color"
          fillRule="nonzero"
          d="M6.804 5.358C3.96 6.295 2 8.955 2 11.996c0 3.04 1.96 5.701 4.804 6.638.525.173 1.09-.113 1.263-.637.173-.525-.113-1.09-.637-1.263C5.399 16.066 4 14.166 4 11.996c0-2.17 1.399-4.07 3.43-4.738.524-.173.81-.738.637-1.263-.173-.524-.738-.81-1.263-.637z"
        />
      </g>
    </svg>
  ),
};
