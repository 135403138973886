import React from 'react';

export default {
  sm: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0H16V16H0z" />
        <path
          className="icon-color"
          fillRule="nonzero"
          d="M8 15c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm0-2c-2.761 0-5-2.239-5-5s2.239-5 5-5 5 2.239 5 5-2.239 5-5 5z"
        />
        <path
          className="icon-color"
          fillRule="nonzero"
          d="M7.689 8.542l-1.506-1.41c-.403-.377-1.036-.355-1.413.048-.378.403-.356 1.036.047 1.413l2.22 2.078c.398.372 1.02.358 1.4-.032l2.868-2.94c.386-.396.378-1.03-.018-1.415-.395-.386-1.028-.378-1.414.018L7.69 8.542z"
        />
      </g>
    </svg>
  ),
  md: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0H24V24H0z" />
        <path
          className="icon-color"
          fillRule="nonzero"
          d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10zm0-2c-4.418 0-8-3.582-8-8s3.582-8 8-8 8 3.582 8 8-3.582 8-8 8z"
        />
        <path
          className="icon-color"
          fillRule="nonzero"
          d="M7.681 10.276c-.403-.378-1.036-.357-1.413.047-.377.403-.356 1.036.047 1.413l4.14 3.876c.398.372 1.02.358 1.4-.032l5.347-5.484c.386-.395.378-1.029-.018-1.414-.395-.386-1.028-.378-1.414.018l-4.663 4.782-3.426-3.206z"
        />
      </g>
    </svg>
  ),
};
