import React from 'react';

export default {
  sm: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0H16V16H0z" />
        <path
          className="icon-color"
          fillRule="nonzero"
          d="M8 15c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm0-2c-2.761 0-5-2.239-5-5s2.239-5 5-5 5 2.239 5 5-2.239 5-5 5z"
        />
        <rect width="2" height="2" x="7" y="10" className="icon-color" rx="1" />
        <path
          className="icon-color"
          fillRule="nonzero"
          d="M7.385 5.933c0-.3.259-.548.585-.548.325 0 .583.249.583.548.003.222-.026.266-.366.514l-.027.02c-.774.565-1.049 1.035-1.007 1.856v.098c-.001.453.365.819.817.819.451 0 .817-.366.817-.818v-.14c-.01-.228.005-.252.337-.495l.027-.02c.767-.56 1.05-1.03 1.037-1.847 0-1.196-.997-2.17-2.218-2.17-1.224 0-2.22.975-2.22 2.183 0 .451.366.817.818.817.451 0 .817-.366.817-.817z"
        />
      </g>
    </svg>
  ),
  md: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0H24V24H0z" />
        <path
          className="icon-color"
          fillRule="nonzero"
          d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10zm0-2c-4.418 0-8-3.582-8-8s3.582-8 8-8 8 3.582 8 8-3.582 8-8 8z"
        />
        <path
          className="icon-color"
          fillRule="nonzero"
          d="M10.442 9.134c0-.77.652-1.405 1.47-1.405.816 0 1.468.635 1.468 1.405.009.562-.134.757-.825 1.262l-.047.034c-1.172.855-1.664 1.537-1.595 2.893l-.001.197c0 .552.448 1 1 1s1-.448 1-1v-.248c-.027-.562.093-.73.774-1.226l.048-.035c1.163-.849 1.667-1.537 1.646-2.892 0-1.871-1.558-3.39-3.468-3.39-1.911 0-3.47 1.519-3.47 3.405 0 .552.448 1 1 1s1-.448 1-1zM12 15.75c.69 0 1.25.56 1.25 1.25s-.56 1.25-1.25 1.25-1.25-.56-1.25-1.25.56-1.25 1.25-1.25z"
        />
      </g>
    </svg>
  ),
};
