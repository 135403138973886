import React from 'react';

export default {
  sm: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0H16V16H0z" />
        <path
          fill="#B2B5B2"
          d="M13 3c1.054 0 1.918.816 1.994 1.85l.005.15v6c0 1.054-.815 1.918-1.85 1.994l-.148.007c0 .512-.386.935-.884.993l-.117.007c-.552 0-1-.448-1-1H5c0 .512-.386.935-.883.993L4 14.001c-.553 0-1-.448-1-1-1.054-.001-1.919-.817-1.995-1.852L1 11V5c0-1.053.816-1.917 1.85-1.994L3 3h10zm0 2H3v6h10V5z"
        />
        <path
          fill="#B2B5B2"
          d="M10.49 6.5c-.827 0-1.5.672-1.5 1.5s.673 1.5 1.5 1.5c.83 0 1.5-.672 1.5-1.5s-.67-1.5-1.5-1.5"
        />
      </g>
    </svg>
  ),
  md: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0H24V24H0z" />
        <path
          fill="#B2B5B2"
          d="M20 4c1.054 0 1.918.816 1.995 1.85L22 6v12c0 1.054-.816 1.918-1.85 1.994l-.153.005c0 .518-.385.94-.883.998l-.117.007c-.512 0-.935-.386-.993-.884L17.997 20H5.998c0 .518-.386.94-.883.998l-.117.007c-.553 0-1-.447-1-1-1.053-.005-1.916-.821-1.992-1.855L2 17.999V6c0-1.053.817-1.917 1.851-1.994L4.001 4h16zm0 2H4v12h16V6z"
        />
        <path
          fill="#B2B5B2"
          d="M14.997 8c-2.209 0-4 1.792-4 4 0 2.21 1.791 4 4 4 2.21 0 4-1.79 4-4 0-2.208-1.79-4-4-4m0 2c1.103 0 2 .897 2 2 0 1.104-.897 2-2 2s-2-.896-2-2c0-1.103.897-2 2-2"
        />
        <path
          fill="#B2B5B2"
          fillRule="nonzero"
          d="M6.998 8c.513 0 .936.386.994.883L7.998 9v6c0 .552-.447 1-1 1-.513 0-.935-.386-.993-.884L5.998 15V9c0-.552.448-1 1-1z"
        />
      </g>
    </svg>
  ),
};
