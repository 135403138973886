import React from 'react';

const icon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="10"
    viewBox="0 0 10 10">
    <path
      fill="#F02525"
      fillRule="evenodd"
      d="M6.569 2.01h-2.6c-.554 0-1.004-.45-1.004-1.005C2.965.45 3.415 0 3.97 0h5.025c.277 0 .529.112.71.294.183.182.295.434.295.711V6.03c0 .555-.45 1.005-1.005 1.005-.555 0-1.005-.45-1.005-1.005V3.431L1.716 9.706c-.393.392-1.03.392-1.422 0-.392-.393-.392-1.03 0-1.422L6.57 2.01z"
    />
  </svg>
);

export default icon;
