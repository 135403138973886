import React from 'react';

export default {
  sm: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0H16V16H0z" />
        <path
          className="icon-color"
          fillRule="nonzero"
          d="M4.275 1.78c-.58.323-.69 1.11-.22 1.58l2.087 2.088c.092.092.074.341-.135.55-.21.21-.46.227-.55.137L3.364 4.044c-.468-.468-1.254-.36-1.578.218C.569 6.427.76 9.01 2.383 10.652l.168.161c1.317 1.194 3.325 1.506 5.175.957l.061-.02 2.54 2.54c.992 1.004 2.659.869 3.74-.182l.134-.138c.996-1.085 1.09-2.657.122-3.638l-2.56-2.561.018-.055c.562-1.907.217-3.987-1.071-5.29C9.074.768 6.466.557 4.275 1.78zm5.012 2.05c.826.837.991 2.421.4 3.808-.16.376-.075.81.213 1.1l3.004 3.003c.173.175.138.575-.23.932-.347.337-.767.37-.928.207L8.752 9.886c-.29-.29-.727-.373-1.103-.211-1.402.603-3 .426-3.843-.428l-.126-.136c-.56-.654-.78-1.55-.638-2.48l.012-.07.989.988c.918.918 2.427.814 3.378-.136l.119-.127c.839-.953.896-2.373.017-3.252l-.982-.982.02-.003c1.028-.17 2.02.1 2.692.782z"
        />
      </g>
    </svg>
  ),
  md: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0H24V24H0z" />
        <path
          className="icon-color"
          fillRule="nonzero"
          d="M5.986 2.524c-.65.279-.812 1.126-.312 1.626l2.462 2.462c.42.42.42 1.103 0 1.524-.422.42-1.103.42-1.525 0L4.15 5.673c-.5-.5-1.347-.338-1.626.312-1.004 2.339-.551 5.027 1.227 6.804l.187.179c1.33 1.22 3.125 1.743 4.915 1.503l.19-.03 6.44 6.44c1.491 1.49 3.908 1.49 5.4.001l.148-.157c1.34-1.5 1.29-3.803-.148-5.243l-6.441-6.44.03-.19c.252-1.875-.335-3.755-1.682-5.102-1.777-1.778-4.465-2.231-6.804-1.227zM8.49 3.999c1.074-.007 2.114.392 2.886 1.166 1.017 1.017 1.385 2.504 1.01 3.953-.088.343.011.707.262.958l6.822 6.82c.708.71.708 1.863-.001 2.573-.71.708-1.86.708-2.57 0l-6.822-6.822c-.25-.25-.615-.35-.958-.261-1.45.374-2.936.006-3.953-1.01l-.15-.158C4.34 10.464 3.993 9.49 4 8.488l.004-.133L5.198 9.55c1.202 1.201 3.149 1.201 4.351 0l.134-.142c1.066-1.21 1.022-3.055-.133-4.21L8.355 4.003l.134-.004z"
        />
      </g>
    </svg>
  ),
};
