import React from 'react';

export default {
  sm: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0H16V16H0z" />
        <path
          className="icon-color"
          fillRule="nonzero"
          d="M6 5V3c0-1.105.895-2 2-2h5c1.105 0 2 .895 2 2v2c0 .364-.097.706-.268 1 .17.294.268.636.268 1v2c0 .364-.097.706-.268 1 .17.294.268.636.268 1v2c0 1.105-.895 2-2 2H8c-.272 0-.532-.054-.768-.153-.394.1-.807.153-1.232.153-2.761 0-5-2.239-5-5s2.239-5 5-5zm4 8h3v-2h-2.1c-.15.739-.463 1.419-.9 2zm.9-4H13V7h-3c.437.581.75 1.261.9 2zM8 5h5V3H8v2zm-2 8c1.657 0 3-1.343 3-3S7.657 7 6 7s-3 1.343-3 3 1.343 3 3 3z"
        />
      </g>
    </svg>
  ),
  md: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0H24V24H0z" />
        <path
          className="icon-color"
          fillRule="nonzero"
          d="M13.899 19H20v-2h-4.674c-.356.75-.841 1.426-1.427 2zm2.03-4H20v-2h-4.07c.046.327.07.66.07 1 0 .34-.024.673-.07 1zm-.603-4H20V9h-6.101c.586.574 1.071 1.25 1.427 2zm-5.325-3.929L10 7V5c0-1.105.895-2 2-2h8c1.105 0 2 .895 2 2v2c0 .364-.097.706-.268 1 .17.294.268.636.268 1v2c0 .364-.097.706-.268 1 .17.294.268.636.268 1v2c0 .364-.097.706-.268 1 .17.294.268.636.268 1v2c0 1.105-.895 2-2 2h-8c-.375 0-.725-.103-1.025-.282C10.348 20.9 9.685 21 9 21c-3.866 0-7-3.134-7-7s3.134-7 7-7c.34 0 .674.024 1.001.071zM20 7V5h-8v2h8zM9 19c2.761 0 5-2.239 5-5s-2.239-5-5-5-5 2.239-5 5 2.239 5 5 5z"
        />
      </g>
    </svg>
  ),
};
