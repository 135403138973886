import React from 'react';

export default {
  sm: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0H16V16H0z" />
        <path
          className="icon-color"
          d="M3 6.5C3 4.57 4.57 3 6.5 3S10 4.57 10 6.5 8.43 10 6.5 10 3 8.43 3 6.5m11.71 6.789l-3.678-3.677c.61-.884.968-1.956.968-3.112C12 3.463 9.537 1 6.5 1 3.462 1 1 3.463 1 6.5 1 9.538 3.462 12 6.5 12c1.158 0 2.232-.36 3.12-.973l3.675 3.676c.39.389 1.025.389 1.414 0 .39-.39.39-1.025 0-1.414"
        />
      </g>
    </svg>
  ),
  md: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0H24V24H0z" />
        <path
          className="icon-color"
          d="M4 10c0-3.31 2.691-6 6-6 3.308 0 6 2.69 6 6 0 3.309-2.692 6-6 6-3.309 0-6-2.691-6-6m17.705 10.293L16.313 14.9C17.366 13.547 18 11.849 18 10c0-4.418-3.582-8-8-8-4.419 0-8 3.582-8 8s3.581 8 8 8c1.848 0 3.545-.633 4.898-1.685l5.393 5.392c.39.389 1.025.389 1.414 0 .39-.39.39-1.025 0-1.414"
        />
      </g>
    </svg>
  ),
};
