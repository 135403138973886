import React from 'react';

export default {
  sm: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0H16V16H0z" />
        <path
          className="icon-color"
          fillRule="nonzero"
          d="M3.413 7.994l3.293-3.286c.391-.39.392-1.023.002-1.414s-1.022-.392-1.413-.002L1.294 7.284c-.391.39-.392 1.024-.002 1.415l4.002 4.01c.39.391 1.022.392 1.413.002.39-.39.39-1.024 0-1.415L3.414 7.994zM12.585 7.995l-3.292 3.297c-.39.391-.39 1.025 0 1.415.391.39 1.024.39 1.414 0l4-4.006c.39-.39.39-1.024-.001-1.415l-3.993-3.993c-.39-.39-1.024-.39-1.414 0-.39.391-.39 1.025 0 1.415l3.286 3.287z"
        />
      </g>
    </svg>
  ),
  md: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0H24V24H0z" />
        <path
          className="icon-color"
          d="M9 19c-.255 0-.511-.099-.706-.293l-6.001-5.999C2.106 12.52 2 12.266 2 12c0-.265.106-.52.293-.707l5.996-6c.39-.39 1.023-.39 1.413 0 .39.391.39 1.023 0 1.414L4.412 12l5.295 5.291c.39.391.39 1.023 0 1.415-.194.195-.45.293-.706.293M15 19c-.256 0-.512-.099-.707-.294-.39-.39-.39-1.023 0-1.414L19.585 12l-5.292-5.293c-.39-.39-.39-1.023 0-1.414.391-.39 1.023-.39 1.414 0l6 6c.39.391.39 1.023 0 1.414l-6 6c-.195.194-.45.292-.707.292"
        />
      </g>
    </svg>
  ),
};
