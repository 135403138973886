import React from 'react';

const icon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="34"
    height="34"
    viewBox="0 0 34 34">
    <g fill="none" fillRule="evenodd">
      <circle cx="17" cy="17" r="17" fill="#008C73" />
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M17 27c5.523 0 10-4.477 10-10S22.523 7 17 7c-5.462 0-9.911 4.382-9.999 9.836-.009.553.432 1.007.984 1.016.552.01 1.007-.431 1.016-.984C9.071 12.506 12.631 9 17 9c4.418 0 8 3.582 8 8s-3.582 8-8 8c-.552 0-1 .448-1 1s.448 1 1 1z"
      />
    </g>
  </svg>
);

export default icon;
