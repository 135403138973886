import React from 'react';

export default {
  sm: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0H16V16H0z" />
        <path
          className="icon-color"
          fillRule="nonzero"
          d="M2 10V6c-.552 0-1-.448-1-1s.448-1 1-1V3c0-1.105.895-2 2-2h8c1.105 0 2 .895 2 2v10c0 1.105-.895 2-2 2H4c-1.105 0-2-.895-2-2v-1c-.552 0-1-.448-1-1s.448-1 1-1zm2 0c.552 0 1 .448 1 1s-.448 1-1 1v1h8V3H4v1c.552 0 1 .448 1 1s-.448 1-1 1v4z"
        />
      </g>
    </svg>
  ),
  md: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0H24V24H0z" />
        <path
          className="icon-color"
          fillRule="nonzero"
          d="M4 15V9H3c-.552 0-1-.448-1-1s.448-1 1-1h1V4c0-1.105.895-2 2-2h12c1.105 0 2 .895 2 2v16c0 1.105-.895 2-2 2H6c-1.105 0-2-.895-2-2v-3H3c-.552 0-1-.448-1-1s.448-1 1-1h1zm2 0h1c.552 0 1 .448 1 1s-.448 1-1 1H6v3h12V4H6v3h1c.552 0 1 .448 1 1s-.448 1-1 1H6v6z"
        />
      </g>
    </svg>
  ),
};
