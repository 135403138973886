import React from 'react';

export default {
  sm: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      transform="rotate(180)">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0H16V16H0z" />
        <path
          className="icon-color"
          d="M12.7 9.297c-.389-.39-1.025-.39-1.414 0l-2.29 2.29V2c0-.55-.45-1-1-1s-1 .45-1 1v9.587l-2.29-2.29c-.39-.389-1.026-.389-1.415 0s-.389 1.025 0 1.414l3.998 3.997c.058.06.132.092.2.133.042.025.076.061.122.081.041.018.087.018.13.028.212.057.436.056.64-.028.044-.019.077-.054.118-.079.07-.041.143-.074.204-.135l3.997-3.997c.39-.389.39-1.025 0-1.414"
        />
      </g>
    </svg>
  ),
  md: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      transform="rotate(180)">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0H24V24H0z" />
        <path
          className="icon-color"
          d="M11 18.586V3c0-.552.448-1 1-1s1 .448 1 1v15.586l4.121-4.122c.39-.39 1.024-.39 1.415 0 .39.391.39 1.024 0 1.415l-5.657 5.657c-.03.029-.06.056-.091.08-.184.234-.468.384-.788.384s-.604-.15-.788-.384c-.031-.024-.062-.051-.09-.08l-5.658-5.657c-.39-.39-.39-1.024 0-1.415.391-.39 1.024-.39 1.415 0L11 18.586z"
        />
      </g>
    </svg>
  ),
};
