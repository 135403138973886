import React from 'react';

export default {
  sm: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0H16V16H0z" />
        <path
          className="icon-color"
          d="M8 10.999c-1.655 0-3-1.346-3-3 0-1.653 1.345-3 3-3 1.653 0 3 1.347 3 3 0 1.654-1.347 3-3 3m6.066-4.208l-.3-.03c-.683-.068-1-.437-1.137-.67-.068-.255-.109-.741.316-1.286l.2-.242c.54-.607.353-.963-.036-1.352l-.322-.321c-.389-.39-.744-.575-1.352-.036l-.235.192c-.535.439-1.025.397-1.285.328-.232-.135-.609-.451-.677-1.141l-.03-.3C9.16 1.12 8.776 1 8.228 1H7.77c-.549 0-.932.12-.98.933l-.03.31c-.084.686-.458 1-.686 1.133-.26.068-.747.105-1.277-.33l-.235-.192c-.608-.54-.963-.353-1.352.036l-.322.32c-.389.39-.575.746-.035 1.353l.2.242c.424.545.383 1.03.315 1.286-.137.233-.454.602-1.137.67l-.3.03c-.81.049-.932.43-.932.98v.456c0 .55.121.933.932.98l.31.03c.686.085 1 .458 1.133.686.068.262.105.748-.33 1.278l-.192.235c-.539.607-.353.963.036 1.352l.322.32c.39.39.744.576 1.352.037l.242-.2c.54-.421 1.021-.384 1.277-.317.23.135.596.449.68 1.128l.03.31c.047.812.43.933.98.933h.455c.55 0 .932-.121.981-.932l.03-.301c.067-.683.437-1 .67-1.137.255-.067.741-.11 1.285.316l.242.2c.608.539.963.354 1.352-.036l.322-.321c.39-.39.575-.745.036-1.352l-.192-.235c-.435-.53-.398-1.016-.33-1.278.133-.228.447-.601 1.133-.686l.31-.03c.811-.047.932-.43.932-.98v-.455c0-.55-.12-.932-.932-.981"
        />
      </g>
    </svg>
  ),
  md: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0H24V24H0z" />
        <path
          className="icon-color"
          d="M12.01 18c-3.309 0-6-2.691-6-6 0-3.31 2.691-6 6-6s6 2.69 6 6c0 3.309-2.691 6-6 6m9.141-7.211l-.346-.03c-.693-.074-.954-.491-1.046-.717-.192-.763-.493-1.48-.886-2.14-.095-.22-.217-.705.229-1.256l.222-.266c.486-.554.292-.901-.096-1.291l-.322-.321c-.389-.39-.737-.582-1.291-.097l-.266.223c-.585.472-1.096.308-1.294.212-.619-.364-1.291-.645-2.002-.833-.165-.046-.729-.263-.816-1.082l-.031-.346c-.049-.735-.43-.845-.981-.845h-.455c-.55 0-.933.11-.981.845l-.031.346c-.102.959-.863 1.095-.863 1.095l.001.006c-.658.18-1.281.445-1.859.777-.002-.003-.003-.008-.006-.011 0 0-.634.442-1.384-.163L6.38 4.67c-.554-.485-.902-.292-1.291.097l-.321.32c-.389.39-.582.738-.098 1.292l.224.266c.606.75.163 1.385.163 1.385.005.004.011.006.016.009-.332.579-.595 1.2-.774 1.859l-.014-.004s-.135.76-1.094.864l-.347.03c-.734.049-.844.432-.844.982v.455c0 .549.11.932.844.98l.347.03c.959.103 1.094.865 1.094.865l.012-.003c.18.658.438 1.283.768 1.862l-.008.004s.443.635-.163 1.385l-.224.266c-.484.554-.292.903.098 1.29l.321.323c.389.389.737.582 1.291.096l.267-.222c.75-.607 1.384-.164 1.384-.164l.002-.003c.58.333 1.204.592 1.862.773v.003s.761.136.863 1.095l.031.345c.048.735.431.844.981.844h.455c.551 0 .932-.11.981-.844l.031-.345c.08-.748.556-.994.764-1.066.727-.186 1.411-.475 2.042-.844.188-.094.707-.278 1.306.206l.266.223c.554.485.902.292 1.291-.097l.322-.322c.388-.388.582-.737.096-1.291l-.222-.265c-.479-.594-.305-1.11-.209-1.301.382-.65.68-1.352.869-2.101.094-.227.354-.637 1.043-.711l.346-.031c.734-.048.843-.431.843-.98v-.455c0-.55-.109-.933-.843-.982"
        />
      </g>
    </svg>
  ),
};
