import React from 'react';

export default {
  sm: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0H16V16H0z" />
        <path
          className="icon-color"
          fillRule="nonzero"
          d="M8.555 1.167l.097.073 7.001 6.007c.42.36.468.991.108 1.41-.332.387-.895.458-1.31.184l-.1-.076-.35-.3v4.54c0 1.053-.816 1.918-1.85 1.994l-.15.006h-8c-1.055 0-1.918-.817-1.994-1.851L2 13.004v-4.54l-.35.301c-.387.332-.954.317-1.322-.017l-.088-.09C-.09 8.27-.075 7.703.26 7.335l.09-.088 7-6.007c.344-.294.838-.319 1.206-.073zm-.554 2.15l-4 3.431v6.257h8V6.748l-4-3.431z"
        />
      </g>
    </svg>
  ),
  md: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0H24V24H0z" />
        <path
          className="icon-color"
          fillRule="nonzero"
          d="M12.576 2.176l.099.077 8.999 7.999c.412.367.45.999.083 1.412-.339.38-.904.442-1.313.16l-.1-.077-.34-.303.001 8.554c0 1.055-.816 1.918-1.85 1.995l-.15.005h-12c-1.055 0-1.918-.815-1.994-1.85l-.006-.15v-8.546l-.34.303c-.381.338-.95.333-1.323.005l-.09-.089c-.338-.38-.332-.948-.005-1.322l.089-.09 9.01-8.006c.347-.309.856-.335 1.23-.077zm-6.57 17.822h12V9.668l-5.997-5.33-6.005 5.336.001 10.324z"
        />
      </g>
    </svg>
  ),
};
