import React from 'react';

export default {
  sm: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0H16V16H0z" />
        <path
          className="icon-color"
          d="M8.998 3c.92 0 1.666.747 1.666 1.666 0 .919-.747 1.666-1.666 1.666-.918 0-1.665-.747-1.665-1.666C7.333 3.747 8.08 3 8.998 3m6.96 10.715c-.67-2.302-1.854-5.203-4.58-6.283.78-.673 1.286-1.657 1.286-2.766C12.664 2.645 11.02 1 8.998 1c-2.02 0-3.665 1.645-3.665 3.666 0 1.114.51 2.102 1.297 2.774-.128.052-.258.098-.382.159-.497.242-.703.84-.462 1.337.243.496.842.705 1.337.461.553-.269 1.167-.4 1.877-.4 2.453 0 3.96 1.578 5.038 5.278.127.437.526.72.96.72.092 0 .187-.013.28-.04.531-.154.834-.71.68-1.24M4.998 10.992h1c.552 0 1 .448 1 1 0 .553-.448 1-1 1h-1v1c0 .552-.447 1-1 1-.552 0-1-.448-1-1v-1H2c-.553 0-1-.447-1-1 0-.552.447-1 1-1h.998v-.998c0-.553.448-1 1-1 .553 0 1 .447 1 1v.998z"
        />
      </g>
    </svg>
  ),
  md: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0H24V24H0z" />
        <path
          className="icon-color"
          d="M13.01 4c1.655 0 3 1.346 3 3s-1.345 3-3 3c-1.653 0-3-1.346-3-3s1.347-3 3-3m9.958 16.726c-1.131-3.876-3.007-8.232-7.486-9.406 1.503-.863 2.529-2.466 2.529-4.32 0-2.757-2.243-5-5-5s-5 2.243-5 5c0 1.859 1.032 3.466 2.542 4.327-.656.177-1.28.419-1.86.743-.482.27-.654.879-.383 1.361.268.48.879.655 1.36.383.973-.543 2.065-.808 3.338-.808 3.942 0 6.347 2.476 8.04 8.28.127.437.526.72.96.72.092 0 .187-.013.28-.04.53-.154.834-.71.68-1.24M6.999 17.003h2c.552 0 1 .448 1 1 0 .553-.448 1-1 1h-2v2c0 .552-.447 1-1 1-.552 0-1-.448-1-1v-2h-2c-.551 0-1-.447-1-1 0-.552.449-1 1-1h2v-1.999c0-.553.448-1 1-1 .553 0 1 .447 1 1v1.999z"
        />
      </g>
    </svg>
  ),
};
