import React from 'react';

export default {
  sm: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0H16V16H0z" />
        <path
          className="icon-color"
          d="M7 15.002v-1H4v-2h6.002v-3H5c-.552 0-1-.449-1-1V3c0-.553.448-1 1-1h2V1h2v1h3.002v2H6v3.001h5.002c.552 0 1 .447 1 1v5.001c0 .553-.448 1-1 1H9v1H7z"
        />
      </g>
    </svg>
  ),
  md: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0H24V24H0z" />
        <path
          className="icon-color"
          d="M11 18v-1H7.999v-2h6v-2.001H9c-.552 0-1-.447-1-1v-4c0-.553.448-1 1-1h2v-1h2v1h3v2h-6v2h5c.554 0 1 .448 1 1V16c0 .553-.446 1-1 1h-2v1h-2z"
        />
        <path
          className="icon-color"
          d="M12 2C6.478 2 2 6.478 2 12s4.478 10 10 10c5.523 0 10-4.478 10-10S17.523 2 12 2m0 2c4.41 0 8 3.589 8 8s-3.59 8-8 8c-4.411 0-8-3.589-8-8s3.589-8 8-8"
        />
      </g>
    </svg>
  ),
};
