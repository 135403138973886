import React from 'react';

export default {
  sm: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0H16V16H0z" />
        <path
          className="icon-color"
          d="M8.269 5.02c1.913.098 3.616 1.219 4.544 2.98-.18.34-.395.646-.626.937l1.41 1.41C14.186 9.66 14.672 8.874 15 8c-1.1-2.926-3.818-5-7-5-.538 0-1.054.08-1.56.192L8.27 5.018zM8 11c-2.022 0-3.842-1.155-4.812-3 .274-.521.625-.977 1.02-1.377l4.34 4.339C8.367 10.981 8.186 11 8 11m4.706 1.291l-.591-.591.004-.003-1.44-1.439-.005.002-4.806-4.805.005-.002-1.472-1.472-.005.003-.691-.691c-.391-.391-1.023-.391-1.414 0s-.391 1.023 0 1.414l.524.525C2.039 6.008 1.401 6.935 1 8c1.101 2.927 3.818 5 7 5 .776 0 1.522-.129 2.226-.36l1.066 1.065c.195.195.451.293.707.293.256 0 .512-.098.707-.293.391-.391.391-1.023 0-1.414"
        />
      </g>
    </svg>
  ),
  md: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0H24V24H0z" />
        <path
          className="icon-color"
          d="M9.293 7.455C10.152 7.168 11.059 7 12 7c3.354 0 6.348 1.938 7.823 5-.643 1.334-1.587 2.436-2.71 3.277l1.43 1.43C20.073 15.494 21.28 13.874 22 12c-1.573-4.098-5.454-7-10-7-1.514 0-2.95.328-4.255.908l1.548 1.547z"
        />
        <path
          className="icon-color"
          d="M11.837 10.001c.055-.004.107-.017.164-.017 1.103 0 2 .898 2 2 0 .057-.013.11-.018.163l1.594 1.594c.263-.532.424-1.123.424-1.757 0-2.209-1.79-4-4-4-.634 0-1.225.162-1.757.423l1.593 1.594zM12 17c-3.354 0-6.348-1.938-7.822-5 .426-.887.994-1.666 1.649-2.344l2.185 2.186c-.002.048-.01.094-.01.142 0 2.21 1.79 4 4 4 .048 0 .095-.008.143-.01l.946.945c-.36.048-.721.081-1.091.081m5.817 1.817L4.762 5.764c-.39-.39-1.023-.39-1.413 0-.392.39-.392 1.023 0 1.414l1.074 1.074C3.388 9.314 2.546 10.577 2 12c1.572 4.098 5.455 7 10 7 .964 0 1.895-.144 2.783-.389l1.62 1.62c.196.196.45.293.707.293.256 0 .512-.097.707-.293.39-.39.39-1.023 0-1.414"
        />
      </g>
    </svg>
  ),
};
