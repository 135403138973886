import React from 'react';

export default {
  sm: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0H16V16H0z" />
        <path
          className="icon-color"
          d="M4 13V3h3.006v3.323c0 .926.755 1.68 1.68 1.68h3.312V13H4zm7.178-6.998H9.007V3.806l2.17 2.196zm2.641-.17L9.226 1.182C9.11 1.065 8.953 1 8.788 1H3.5C2.671 1 2 1.627 2 2.4v11.2c0 .773.672 1.4 1.5 1.4h8.998c.829 0 1.5-.627 1.5-1.4V6.262c0-.16-.063-.317-.178-.43z"
        />
      </g>
    </svg>
  ),
  md: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0H24V24H0z" />
        <path
          className="icon-color"
          d="M6 20V4h5.998v4.02c0 1.088.889 1.974 1.98 1.974H18V20H6zM17.175 8.003l-3.177.017V4.83l3.178 3.173zm2.56-.27l-5.432-5.426c-.198-.197-.464-.307-.74-.307H6c-1.104 0-2 .896-2 2v16c0 1.104.896 2 2 2h12c1.105 0 2-.896 2-2V8.371c0-.239-.096-.469-.265-.638z"
        />
      </g>
    </svg>
  ),
};
