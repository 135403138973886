import React from 'react';

const icon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="10"
    viewBox="0 0 10 10">
    <path
      fill="#FFFFFF"
      fillRule="evenodd"
      d="M3.431 7.99h2.6c.554 0 1.004.45 1.004 1.005C7.035 9.55 6.585 10 6.03 10H1.005c-.277 0-.529-.112-.71-.294C.111 9.524 0 9.272 0 8.995V3.97c0-.555.45-1.005 1.005-1.005.555 0 1.005.45 1.005 1.005v2.599L8.284.294c.393-.392 1.03-.392 1.422 0 .392.393.392 1.03 0 1.422L3.43 7.99z"
    />
  </svg>
);

export default icon;
